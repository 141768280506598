import React from "react"
import DescriptionStyle from "./descriptionStyle"

const Description = () => {
  return (
    <DescriptionStyle>
      <div className="container">
        <h2>We’re here to help.</h2>
        <p>
          Need more information on how Lateral Partners could help with your
          development finance process? Book a time to chat with Ben Pauley
          below.
        </p>
        <p>
          From securing funding for your development, right through to seeking
          funding for end buyers to help you and your team lock in pre-sales,
          Ben can walk you through the whole process. Tell us a little more
          about your development below and we’ll be in touch.
        </p>
      </div>
    </DescriptionStyle>
  )
}

export default Description
